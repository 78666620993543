<template>
  <validation-observer
    ref="bankForm"
  >
    <b-toast
      :visible="isFailed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form @submit.prevent="onSubmit">
      <validation-provider
        v-slot="{ errors }"
        name="firstname"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('user.firstname')"
          label-for="bank-firstname"
        >
          <b-form-input
            id="bank-firstname"
            v-model="playerBankAccountData.firstname"
            :state="errors[0] ? false : null"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="lastname"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('user.lastname')"
          label-for="bank-lastname"
        >
          <b-form-input
            id="bank-lastname"
            v-model="playerBankAccountData.lastname"
            :state="errors[0] ? false : null"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="bankAccountNumber"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="$t('bank_accounts.account_no')"
          label-for="bankAccountNumber"
        >
          <b-form-input
            id="bankAccountNumber"
            v-model="playerBankAccountData.bankAccountNumber"
            :state="errors[0] ? false : null"
            readonly="readonly"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        :name="$t('bank_accounts.bank')"
        rules="required"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('bank_accounts.bank')} *`"
          label-for="bankCode"
        >
          <b-form-select
            id="bankCode"
            v-model="playerBankAccountData.bankCode"
            :state="errors[0] ? false : null"
          >
            <template #first>
              <b-form-select-option
                value=""
                disabled
              >
                {{ `-- ${$t('fields.click_select')} --` }}
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="bank in thBanks"
              :key="bank.bank_code"
              :value="bank.bank_code"
            >
              {{ bank.bank_name_th }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </validation-provider>

      <div class="text-right">
        <b-button
          class="mr-2"
          variant="light"
          @click="setData()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-overlay
          :show="isUpdatingPlayer"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { banks } from '@/libs/constants.js';
import { required } from '@validations'

export default {
  data() {
    return {
      required,
      isFailed: false,

      thBanks: banks,
      playerBankAccountData: {
        bankCode: '',
        bankAccountNumber: '',
        firstname: '',
        lastname: '',
      },
    }
  },
  computed: {
    ...mapState({
      isUpdatingPlayer: (state) => state.player.isUpdatingPlayer,
    }),
    ...mapGetters(['playerDetail']),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  watch: {
    playerDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    this.setData()
  },
  methods: {
    ...mapActions(['updatePlayer']),
    setData() {
      if (this.playerDetail && this.playerDetail.bankAccount) {
        const data = JSON.parse(JSON.stringify(this.playerDetail))
        if (data) {
          const { profile, bankAccount } = data;
          this.playerBankAccountData.bankCode = bankAccount.bankCode
          this.playerBankAccountData.bankAccountNumber = bankAccount.bankAccountNumber
          this.playerBankAccountData.firstname = profile.firstname || ''
          this.playerBankAccountData.lastname = profile.lastname || ''
        }
      } else {
        this.playerBankAccountData = {
          bankCode: '',
          bankAccountNumber: '',
          firstname: '',
          lastname: '',
        }
      }
    },
    onSubmit() {
     this.$refs.bankForm.validate().then( async (success) => {
        if (success) {
          this.isFailed = false
          this.updatePlayer({ playerId: this.playerId, data: this.playerBankAccountData})
        } else {
          this.isFailed = true
        }
      })
    },
  },
}
</script>
